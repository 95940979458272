<template>
	<layout-div>
		<div class="card">
			<form>
				<div class="card-header">
					<button @click="handleSave()" :disabled="isSaving" type="button" class="btn btn-info mt-3">
						儲存
					</button>

					<router-link class="btn btn-default mt-3 float-right" to="/admin/Dtu/index">
						返回列表
					</router-link>
				</div>
				<div class="card-body">
					<div class="form-horizontal col-md-8">
						<div class="form-group row">
							<label htmlFor="dtu_sn" class="col-sm-2 col-form-label">DTU型號</label>
							<div class="col-sm-10">
								<select v-model="model.dtu_sn" class="form-control" id="dtu_sn" name="dtu_sn">
									<option value="TAS-L15">TAS-L15</option>
									<option value="T310(分享器)">T310(分享器)</option>
								</select>

							</div>
						</div>
						<div class="form-group row">
							<label htmlFor="housecode" class="col-sm-2 col-form-label">案場代號</label>
							<div class="col-sm-10">
								<input v-model="model.housecode" type="text" class="form-control" id="housecode"
									name="housecode" required />
							</div>
						</div>
						<div class="form-group row">
							<label htmlFor="dtu_brate" class="col-sm-2 col-form-label">串埠速率</label>
							<div class="col-sm-10">
								<input v-model="model.dtu_brate" type="text" class="form-control" id="dtu_brate"
									name="dtu_brate" required />
								<font color="blue">速率一定要跟逆變器相同</font>
							</div>
						</div>
						<div class="form-group row">
							<label htmlFor="cntrl" class="col-sm-2 col-form-label">MQTT開關</label>
							<div class="col-sm-10">
								<input v-model="model.cntrl" type="text" class="form-control" id="cntrl" name="cntrl" />
							</div>
						</div>
						<div class="form-group row">
							<label htmlFor="dtu_ip" class="col-sm-2 col-form-label">MQTT連線狀態</label>
							<div class="col-sm-10">
								<input v-model="model.dtu_ip" type="text" class="form-control" id="dtu_ip"
									name="dtu_ip" />
							</div>
						</div>
						<div class="form-group row">
							<label htmlFor="tttdtu_id" class="col-sm-2 col-form-label">DTU編號</label>
							<div class="col-sm-10">
								<input v-model="model.tttdtu_id" type="text" class="form-control" id="tttdtu_id"
									name="tttdtu_id" />
							</div>
						</div>
						<div class="form-group row">
							<label htmlFor="simid" class="col-sm-2 col-form-label">SIM編號</label>
							<div class="col-sm-10">
								<input v-model="model.simid" type="text" class="form-control" id="simid" name="simid" />
								<button class="btnbtn-app btn-primary" @click="showSimSelectForm()">選取未使用門號</button>
							</div>
						</div>
						<div class="form-group row">
							<label htmlFor="owner" class="col-sm-2 col-form-label">門號擁有人</label>
							<div class="col-sm-10">
								<input v-model="model.owner" type="text" class="form-control" id="owner" name="owner" />
							</div>
						</div>

						<div class="form-group row">
							<label htmlFor="note" class="col-sm-2 col-form-label">備註</label>
							<div class="col-sm-10">
								<input v-model="model.note" type="text" class="form-control" id="note" name="note" />
							</div>
						</div>

					</div>
				</div>
			</form>
		</div>
	</layout-div>
	<div class="modal fade" id="SimModal">
		<div class="modal-dialog modal-lg">
			<div class="modal-content">
				<div class="modal-header">
					<h4 class="modal-title">未使用SIM卡</h4>
					<button type="button" class="close" data-dismiss="modal" aria-label="Close">
						<span aria-hidden="true">&times;</span>
					</button>
				</div>
				<div class="modal-body">
					<table class="table table-bordered">
						<thead>
							<tr>
								<th>選取</th>
								<th>id</th>
								<th>門號</th>
								<th>ICCID編號</th>
								<th>電信業者</th>
								<th>啟用日期</th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="item in simList" :key="item.id">
								<td><input v-model="item.id" type="radio" class="form-control" name="id" />
									{{ item.id }}</td>
								<td>{{ item.id }}</td>
								<td>{{ item.simnumber }}</td>
								<td>{{ item.sim }}</td>
								<td>{{ item.telcom }}</td>
								<td>{{ item.dt1 }}</td>
							</tr>
						</tbody>
					</table>
				</div>
				<div class="modal-footer justify-content-between">
					<button type="button" class="btn btn-default" data-dismiss="modal">關閉</button>
					<button type="button" class="btn btn-primary" @click="pick()">選取</button>
				</div>
			</div>
			<!-- /.modal-content -->
		</div>
		<!-- /.modal-dialog -->
	</div>
</template>
<script>
import $ from 'jquery'
import LayoutDiv from '@/components/LayoutDiv.vue'
import { UnWorkSim } from '@/api/Sim.js'
import { addDtu } from '@/api/Dtu.js'
import toastr from 'toastr'

export default {
	name: 'ProjectCreate',
	components: {
		LayoutDiv,
		//			SimSelect
	},
	data() {
		return {
			model: {
				dtu_sn: '',
				housecode: '',
				dtu_brate: '',
				cntrl: '',
				dtu_ip: '',
				tttdtu_id: '',
				simid: '',
				owner: '',
				iptime: '',
				dt1: '',
				note: '',

			},
			isSaving: false,
			simList: []
		};
	},
	methods: {
		checkFields() {
			let test = true;
			if (this.model.dtu_brate == "") test = this.pickupWarning("串埠速率欄位必填");
			return test;
		},
		pickupWarning(message) {
			toastr.error(message);
			return false;
		},

		handleSave() {
			if (this.isSaving == true) return;
			this.isSaving = true;

			if (this.checkFields() == false) {
				this.isSaving = false
				return false;
			}

			addDtu(this.model).then(rep => {

				this.isSaving = false;
				this.reset();
				return rep;
			})
				.catch(error => {
					this.isSaving = false
					return error
				});
		},
		reset() {
			this.model.dtu_sn = "";
			this.model.housecode = "";
			this.model.dtu_brate = "";
			this.model.cntrl = "";
			this.model.dtu_ip = "";
			this.model.tttdtu_id = "";
			this.model.simid = "";
			this.model.owner = "";
			this.model.iptime = "";
			this.model.dt1 = "";
			this.model.note = "";
		},
		fetchSimList() {

			UnWorkSim().then(rep => {

				let sc = rep.data;
				alert(JSON.stringify(sc));
				this.simList = sc;

			});
		},
		showSimSelectForm() {
			this.fetchSimList();
			$("#SimModal").modal('show');

			//SimSelect.show();
		},
		pick() {
			alert($("#id").value());
		}
	}
};
</script>
