import { apiGet, apiPost } from './service'

export async function listSim(data) {
	return await apiPost("/api/Sim/list", data);
}

export async function fullListSim(data) {
	return await apiPost("/api/Sim/fulllist", data);
}

export async function UnWorkSim() {
	return await apiPost("/api/Sim/listnowork");
}

export function getSim(data) {
	let url = "/api/Sim/read/" + data;
	return apiGet(url, {params: {timestamp: Math.random()}});
}

export function postSim(data) {
	return apiPost("/api/Sim/edit", data);
}

export function addSim(data) {
	return apiPost("/api/Sim/add", data);
}

export function deleteSim(data) {
	return apiGet("/api/Sim/delete/" + data);
}
